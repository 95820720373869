.related-pages {
  margin-bottom: calc(14 * var(--flow-space));

  @media (--viewport-medium) {
    margin-bottom: calc(15 * var(--flow-space));
  }
}

.article + .related-pages {
  margin-top: calc(8 * var(--flow-space));
}

.related-pages .section-title {
  text-align: center;
  margin-bottom: 3rem;
}

.related-pages .cards {
  display: flex;
}

.related-pages .card {
  > a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.related-pages .card-content {
  flex: 1;
}

.related-pages .tns-nav {
  bottom: -3rem;
}


.related-pages .tns-nav button {
  @mixin paginationEl;
}

.related-pages .tns-nav .tns-nav-active {
  @mixin paginationEl:active;
}

.related-pages .tns-controls {
  width: 8rem;
  left: 50%;
  right: auto;
  top: auto;
  bottom: -8rem;
  transform: translateX(-50%);

  & button {
    /* transform: translateY(7.3rem) scale(0.5); */
  }
}

.related-pages .tns-controls [data-controls] {
  transform: none;
}
