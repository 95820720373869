/* layout */

.site-footer {
  padding-top: var(--flow-space-2);
  padding-bottom: var(--flow-space-2);

  background: var(--color-text);
  color: var(--color-white);
}

.footer-navigation {
  margin-bottom: var(--flow-space-2);
}

@media (--viewport-large) {

  .site-footer {
    background-image: linear-gradient(
      90deg,
      var(--color-text) 0%,
      var(--color-text) 50%,
      var(--color-primary) 50%,
      var(--color-primary) 100%
    );
  }

  .footer-branding {
    grid-column: 1 / span 11;
    grid-row: 1;
  }

  .footer-navigation {
    grid-column: 14 / span 10;
    grid-row: 1;
    margin-bottom: 0;
  }
}

@media (--viewport-large) {

  .footer-branding {
    grid-column: 2 / span 11;
  }

  .footer-navigation {
    grid-column: 14 / span 10;
  }
}

/* styles */

.site-footer .logo {
  font-size: 1.2rem;
  /* width: 20em; */
  display: flex;
  align-items: center;
  color: var(--color-grey);
  margin-left: -1rem;

  > * {
    padding-left: 1rem;
  }
}

.site-footer .logo svg {
  width: 10em;
  height: auto;

  & path {
    fill: currentColor;
  }
}

.site-footer .logo .tagline-html {
  width: 50%;
  /* white-space: nowrap; */
  transform: translateY(0.25em);

  font-size: 0.6em;
  font-family: var(--font-family-serif);
}

.site-footer .logo #Tagline {
  display: none;
}

/* Menus */

.footer-menu {
  @mixin uiList;
}

.footer-menu li {
  @mixin main-navigation-link;
}

.footer-menu a {
  padding: 0.25rem 0;
  display: block;
}

@media (--viewport-smallest-to-large) {

  .footer-navigation .footer-menu {
    margin-bottom: 1rem;
  }
}

@media (--viewport-large) {

  .footer-navigation {
    display: flex;
    justify-content: space-between;
    /* justify-content: flex-end; */
    align-items: center;
  }

  .footer-menu {
    display: flex;
    margin-left: -1rem;
    margin-right: 2rem;
  }

  .footer-menu li {
    padding-left: 1rem;
  }
}

/* Social Menu */

.social-menu {
  @mixin uiList;
  display: flex;
  /* align-items: center; */
  height: 1.5rem;
  margin-left: -0.75rem;
}

.social-menu > li {
  padding-left: 0.75rem;
  height: 100%;
}

.social-menu a {
  display: block;
  height: 100%;
}

.social-menu svg {
  height: 100%;
  width: auto;
}

.social-menu path {
  fill: var(--color-white);
}
