html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.site-content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.wrap {
  max-width: 84rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--outer-gutters);
  padding-right: var(--outer-gutters);
}

/* === LAYOUT === */

@define-mixin grid {
  --gutter: 0;
  width: 100%;
  display: grid;
  grid-column-gap: var(--gutter);
  grid-template-columns: 1fr;

  @media (--viewport-large) {
    grid-template-columns: repeat(24, 1fr);
  }
}

.grid {
  @mixin grid;
}
