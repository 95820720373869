:root {
  --header-height: 7.25rem;

  @media (--viewport-medium) {
    /* --header-height: 10rem; */
  }
}

.site-content {
  position: relative;
}

.filter .translation-missing .block--text {
  grid-column: 1 / -1;
}

.notification-missing-translation {
  padding: 0.5rem;
  text-align: center;
  background-color: lightgoldenrodyellow;
  margin-bottom: 1rem;
}

.site-header {
  --gap: 0.5rem;
  --menu-row-height: auto;
  --header-bottom-gap: calc(var(--flow-space) * 1.5);

  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  color: var(--color-white);
  height: var(--header-height);

  @media (--viewport-smallest-to-large) {
    overflow: hidden;
  }
}

.site-header-inner {
  @mixin grid;
  height: auto;

  position: relative;

  @media (--viewport-smallest-to-large) {
    padding-top: 2rem;
    grid-template-rows: auto 1fr;
    height: calc(100% - 0rem);
  }

  @media (--viewport-large) {
    align-items: end;
  }
}

@media (--viewport-large) {
  .site-header-inner .logo {
    grid-column: 1 / 4;
  }

  .main-navigation {
    grid-column: 5 / -1;
    margin-left: auto;
  }
}

@media (--viewport-extra-large) {
  .site-header-inner .logo {
    grid-column: 2 / 8;
  }

  .main-navigation {
    grid-column: 8 / 24;
  }
}

@media (--viewport-large) {
  .site-header-inner::after {
    content: "";
    display: block;
    height: 1px;
    box-shadow: 0 1px 0 0 var(--color-white);
    position: absolute;
    bottom: 0;
    opacity: 0.5;

    left: var(--outer-gutters);
    right: var(--outer-gutters);
  }
}

.logo {
  margin-bottom: var(--gap);
  display: inline-block;
  @media (--viewport-medium) {
    transform: translateX(-23px);
  }
}

.logo svg {
  height: 3.75rem;
  width: auto;
  vertical-align: bottom;

  & path {
    fill: var(--color-white);
  }

  @media (--viewport-extra-large) {
    height: 4.3125rem;
  }
}

.site-header .logo #Pin path {
  fill: var(--color-tertiary);
}

@media (--viewport-large) {
  .site-header {
    padding-top: 0;
  }

  .logo #Pin {
    --offset: 16px;

    transition: 0.25s;
    transform-origin: var(--offset) var(--offset);
  }

  .logo:hover #Pin {
    transform: translate3d(0, 0, 0) rotate(-45deg);
  }
}

/* === MAIN NAVIGATION === */

.menu {
  @mixin uiList;
}

.menu > li > a,
.menu > li > .submenu-list {
  transition: background-color 0.25s, opacity 0.25s;
}

@media (hover: hover) and (pointer: fine) {
  .menu > li:hover > a,
  .menu > li:hover .submenu-list {
    background-color: var(--color-menu-bg);
  }
}

@media (--viewport-large) {
  .menu {
    /* flex-wrap: wrap; */
    margin-left: calc(-2 * var(--gap));

    & > li {
      margin-left: var(--gap);
      height: var(--menu-row-height);
      display: flex;
      align-items: stretch;
      position: relative;
      z-index: 1;
    }

    & > li > a {
      white-space: nowrap;
      display: flex;
      align-items: flex-end;
      padding: calc(var(--gap) / 2) var(--gap);

      text-transform: uppercase;
      letter-spacing: 0.075em;
    }
  }

  .menu > .active > a {
    background-color: var(--color-menu-active);
  }

  .menu > * + * {
    margin-left: 1rem;
  }

  .menu [aria-current] {
    font-weight: bold;
  }
}

.menu a:hover {
  text-decoration: none;
}

/* Submenu */

.menu .submenu-list {
  width: 12rem;
  opacity: 0;
  display: none;
  pointer-events: none;
}

.menu .submenu-list {
  padding: 0.5rem 0;
  position: absolute;
  z-index: 1;
  top: var(--menu-row-height);
  left: 0;
}

.menu .submenu-list > li > a {
  padding: calc(var(--gap) / 2) var(--gap);
  display: block;
}

.menu .submenu-list a:hover {
  background-color: var(--color-menu-hover);
}

/* Mobile */

.menu-open .site-header {
  background: var(--color-white);
  height: 100vh;

  & .logo svg #Wordmark path,
  & .logo svg #Tagline path {
    fill: var(--color-primary);
  }
}

.open-menu,
.close-menu {
  @mixin circularButton;
  background: #000;
  display: none;
  position: absolute;
  top: 2.5rem;
  right: var(--outer-gutters);
}

.open-menu {
  background: hsla(0, 0%, 100%, 0.7);

  path {
    fill: var(--color-primary);
  }
}

.close-menu {
  background: var(--color-primary);

  path {
    fill: var(--color-white);
  }
}

@media (--viewport-smallest-to-large) {
  .main-navigation {
    margin-right: calc(-2 * var(--outer-gutters));
  }

  .menu > li {
    box-shadow: inset 0 1px 0 0 var(--color-primary);
  }

  .menu > li > a {
    padding: 0.75rem 2rem;
    display: block;
    font-size: var(--ms-4);
    font-weight: 300;
    color: var(--color-secondary);
  }

  .menu > li:first-child {
    box-shadow: none;
  }

  .open-menu {
    display: flex;
  }

  .menu-open .close-menu {
    display: flex;
  }

  .menu-open .open-menu {
    display: none;
  }

  .main-navigation {
    margin-top: 1.5rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    & .menu {
      /* height: 1000px; */
    }
  }
}

/* Large */

@media (--viewport-large) {
  .site-header {
    --gap: 1.25rem;
    --menu-row-height: 7rem;
  }

  .logo {
    margin-bottom: var(--header-bottom-gap);
  }

  .menu {
    display: flex;
  }

  .menu > li {
    margin-left: 0;
  }

  .menu > li > a {
    padding: 0 var(--gap) var(--header-bottom-gap);
  }

  .menu .submenu-list {
    display: block;
  }

  .menu > li:hover > .submenu-list {
    opacity: 1;
    pointer-events: initial;
  }
}
