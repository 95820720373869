@define-mixin a11ybutton {
  all: unset;
  pointer-events: auto;
  padding: 0.2rem 0.6rem;
  box-shadow: 0 0 0 1px var(--color-primary);
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
}

@define-mixin a11ybuttonActive {
  background: var(--color-primary);
  color: var(--color-white);
  font-weight: bold;
  text-decoration: underline;
  /* box-shadow: 0 0 0 1px var(--color-primary); */
}

.site-header-toprow {
  pointer-events: none;
  color: var(--color-text);
  position: absolute;
  bottom: 0;
  z-index: 2;
  right: 0;
  left: 0;
  padding-left: var(--outer-gutters);
  padding-right: var(--outer-gutters);
  padding-top: 1rem;
  padding-bottom: 1rem;

  display: flex;
  margin-left: -0.5rem;
  > * {
    padding-left: 0.5rem;
    pointer-events: auto;
  }
  & button {
    cursor: pointer;
  }
}

.languages .active a,
.high-contrast [data-a11y-menu] button {
  @mixin a11ybuttonActive;
}

.languages ul {
  @mixin uiList;
  display: flex;
  margin-left: -0.5rem;
  > * {
    padding-left: 0.5rem;
  }
}

[data-a11y-menu] button,
.languages a {
  @mixin a11ybutton;
  /* display: flex; */

  & svg {
    height: 1rem;
    width: auto;
    margin-right: 0.5rem;
  }
}

@media (--viewport-smallest-to-large) {
  .site-header-toprow {
    display: none;
  }

  .menu-open .site-header-toprow {
    display: flex;
  }
}

@media (--viewport-large) {
  .site-header-toprow {
    font-size: var(--ms-neg-1);
    right: 0;
    left: auto;
    top: 0rem;
    bottom: auto;
  }

  .languages a,
  [data-a11y-menu] button {
    color: var(--color-white);

    & path {
      fill: var(--color-white);
    }
  }

  .languages .active a,
  .high-contrast [data-a11y-menu] button {
    background: var(--color-white);
    color: var(--color-primary);
  }

  .high-contrast [data-a11y-menu] button path {
    fill: black;
  }
}
