:root {
  --hue: 90deg;
  --hue2: 88deg;
  --hue3: 87deg;
  --hue4: 142deg;
  --hue5: 350deg;

  --color-primary: hsl(var(--hue) 100% 37%);
  --color-primary-light: hsl(var(--hue) 54% 97%);
  --color-primary-lighter: hsl(var(--hue) 53% 94%);
  --color-secondary: hsl(var(--hue4) 100% 23%);
  --color-tertiary: hsl(var(--hue5) 100% 46%);

  --color-primary-ultralight: hsl(var(--hue) 2% 98%);
  --color-text: hsl(var(--hue) 5% 20%);

  --color-white: hsl(var(--hue) 5% 99%);
  --color-grey: hsl(var(--hue) 2% 70%);
  --color-black: hsl(var(--hue) 2% 5%);

  --color-grey-client: #999;
  --color-menu-bg: hsl(137deg 76% 28% / 85%);
  --color-menu-active: hsl(137deg 76% 28% / 45%);
  --color-menu-hover: hsl(137deg 76% 28%);
}

body {
  color: var(--color-text);
}

:--heading {
}

a {
  color: currentColor;
}

:root.high-contrast {
  --color-primary: black;
  --color-secondary: black;
  filter: grayscale(100%);

  & .hero,
  & .site-footer {
    background: #000;
  }

  & .bubble img {
    filter: contrast(300%);
  }

  & canvas {
    display: none;
  }

  .open-menu {
    background: #FFF;
  }

  .open-menu path {
    fill: #000;
  }

  .tns-controls path {
    opacity: 1;
  }

  .article-header {
    background: #000;
  }

  .site-header .logo #Pin path {
    fill: #fff;
  }

  .menu-open .site-header .logo #Pin path {
    fill: #000;
  }
}
