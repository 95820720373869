.hero {
  @mixin headerGradient;
  margin-bottom: 4rem;
  overflow: hidden;
  position: relative;
}

.hero-inner {
  background-image: url("../images/bubbles/hero_hg.png");
  background-position: center center;
  background-size: cover;

  height: 100vh;
  padding-top: calc(var(--header-height));
  position: relative;
  z-index: 1;

  @media (--viewport-large) {
    height: 90vh;
  }
}

.temp {
  position: absolute;
  opacity: 0.2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* transform: translate(-50%, -50%) scale(.5); */
}

.bubbles {
  position: absolute;
  left: 50%;
  top: 50%;
}

.bubble {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  & img {
    max-width: none;
  }

  & a {
    pointer-events: auto;
  }
}

.bubbles {
  --scale: 0.45;
  transform: scale(var(--scale)) translate(38px, 20px);
}

.bubble-inner  {
  display: block;
  transform:  translate(var(--x), var(--y));
}

.bubble--modellprojekte {
  --x: 132px;
  --y: -190px;
}

.bubble--fortbildung {
  --x: 230px;
  --y: 60px;
}

.bubble--evaluation {
  --x: 80px;
  --y: 200px;
}

.bubble--theorien {
  --x: -130px;
  --y: 220px;
}

.bubble--oer {
  --x: -280px;
  --y: 10px;
}

.bubble--kunst-kultur {
  --x: -130px;
  --y: -150px;
}

@media (--viewport-medium) {
  .bubbles {
    --scale: 0.8;
  }
}

@media (--viewport-large) {
  .bubbles {
    --scale: 1.2;
    transform: scale(var(--scale)) translate(38px, 45px);
  }
}

.bubble img {
  transition: .25s;
}

.bubble a:hover img {
  transform: scale(1.05);
  filter: drop-shadow(0 0 .5rem hsl(0deg 0% 0% / 25%));
}

@media (min-width: 62rem) and (max-height: 60rem) {
  .hero-inner {
    height: 40rem;
  }
  .bubbles {
    --scale: .8;
    transform: scale(var(--scale)) translate(38px, 80px);
  }
}

/* Canvas */

#canvas {
  position: absolute;
  left: 0;
  top: 0;
}