/* Links */

a {
  text-decoration: none;
}

@media (hover: hover) and (pointer: fine) {
  a:hover {
    text-decoration: underline;
  }
}

/* === MIXINS === */

@define-mixin uiList {
  padding-left: 0;

  & li {
    list-style: none;
  }
}

@define-mixin headerGradient {
  background: linear-gradient(-35deg, hsl(144deg 100% 24%), hsl(90deg 60% 50%));
}

@define-mixin circularButton {
  all: unset;
  pointer-events: auto;
  border-radius: 10rem;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@define-mixin paginationEl {
  all: unset;
  cursor: pointer;
  margin: 0 0.1rem;
  font-size: var(--ms-neg-1);
  width: 24px;
  height: 24px;

  color: var(--color-primary);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  box-shadow: inset 0 0 0 1px var(--color-primary);
  transition: 0.25s color, 0.25s background;
}

@define-mixin paginationEl:active {
  background: var(--color-primary);
  color: var(--color-white);
}

/* Utils */

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
