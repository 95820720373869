/* Main */

.main {
  margin-bottom: var(--flow-space-2);
  @media (--viewport-medium) {
    margin-bottom: calc(4 * var(--flow-space));
  }
}

.article {
  margin-bottom: var(--flow-space-2);

  @media (--viewport-medium) {
    margin-bottom: calc(4 * var(--flow-space));
  }
}

/*
  ------------------------
  Blocks  
  ------------------------
*/

.main-content > .blocks {
  @mixin grid;
}

@define-mixin content-column {
  @media (--viewport-large) {
    grid-column: 2 / 18;
  }
}

.block--intro {
  @media (--viewport-large) {
    grid-column: 2 / 16;
  }
}

.block--heading,
.block--image,
.block--quote,
.block--list,
.block--text,
.block--video {
  @mixin content-column;
}

.block--text a,
.block--list a {
  color: var(--color-primary);
  text-decoration: underline;
}

.block--carousel {
  @media (--viewport-large) {
    grid-column: 3 / 24;
  }
}

.block--slider {
  @media (--viewport-large) {
    grid-column: 1 / -1;
  }
}

.block--slider-inner .wrap {
  @mixin grid;
  align-items: end;

  > * {
    grid-column: 1 / -1;
  }
}

@media (--viewport-large) {
  .block--slider-inner .wrap .block--slider-slides-container {
    grid-column: 9 / -1;
    grid-row: 1;
  }

  .block--slider-inner .wrap .block--carousel-text {
    grid-row: 1;
    grid-column: 1 / 8;
  }
}

/* debug */

.block {
  /* border: 1px solid red; */
}

/* Setup */

.blocks {
}

/* Intro */

.block--intro {
  margin-bottom: calc(2 * var(--flow-space));
}

.flow > * + .block--intro {
  margin-top: calc(3 * var(--flow-space));
}

.block--intro p {
  @mixin big-paragraph;
}

/* Heading */

.block--heading {
  & h1,
  & h2 {
    @mixin sectionTitle;
    color: var(--color-secondary);
  }

  & h3 {
    @mixin smallTitle;
  }
}

.flow > * + .block--heading {
  margin-top: calc(var(--flow-space) * 2);

  @media (--viewport-medium) {
    margin-top: calc(var(--flow-space) * 4);
  }
}

/* Slider */

.block--carousel-text {
  margin-top: calc(var(--flow-space) * 2);
}

.block--slider,
.block--carousel {
  margin-top: calc(var(--flow-space) * 3);
  margin-bottom: calc(var(--flow-space) * 3);

  & ul {
    @mixin uiList;
  }
}

.tns-outer {
  position: relative;
}

.tns-controls:focus {
  outline: none;

  & button {
    outline: none;
  }
}

.tns-controls {
  pointer-events: none;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);

  & > button {
    @mixin circularButton;
    transition: 0.25s opacity;
    background: var(--color-primary);
  }

  & path {
    fill: var(--color-white);
    transition: 0.25s opacity;
    opacity: 0.5;
  }

  & > button:hover path {
    opacity: 1;
  }

  & [data-controls="next"] {
    transform: translateX(-2.7rem);

    & svg {
      transform: translateX(2px);
    }
  }

  & [data-controls="prev"] {
    transform: translateX(2.7rem);

    & svg {
      transform: translateX(-2px);
    }
  }

  & > button:disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.tns-nav {
  position: absolute;
  bottom: -2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.block--carousel,
.block--slider {
  & .tns-nav {
    bottom: -1rem;

    & > button {
      all: unset;
      background: var(--color-black);

      height: 2px;
      width: 1.5rem;
      margin: 0 0.2rem;
    }

    &.tns-nav-active {
      background: var(--color-tertiary);
    }
  }
}

.block--carousel {
  & .tns-outer {
    margin-bottom: calc(0 * var(--flow-space));
  }

  & .tns-nav > button {
    background: var(--color-primary);
  }

  & .tns-nav .tns-nav-active {
    background: var(--color-secondary);
  }

  @media (--viewport-large) {
    .tns-controls {
      --offset: 4.4rem;

      margin: 0 calc(-1 * var(--offset));
      width: calc(100% + 2 * var(--offset));
    }
  }
}

.block--slider {
  & .tns-nav {
    bottom: 1rem;
    z-index: 1;
  }

  & .tns-nav > button {
    background: var(--color-white);
  }

  & .tns-nav .tns-nav-active {
    background: var(--color-primary);
  }
}

.block--slider-inner {
  background: var(--color-primary-lighter);
  padding-top: var(--flow-space-4);
  padding-bottom: var(--flow-space-4);
}

.block--slider :--heading {
  color: var(--color-primary);
}

/*
  ------------------------
  Related Pages Slider
  ------------------------
*/

/* Quote */

.block--quote {
  margin-top: calc(var(--flow-space) * 2);
  margin-bottom: calc(var(--flow-space) * 2);

  @media (--viewport-medium) {
    /* margin-top: calc(var(--flow-space) * 4); */
    /* margin-bottom: calc(var(--flow-space) * 4); */
  }
}

.block--quote blockquote {
  color: var(--color-primary);
  border-left: 2px solid var(--color-primary);
  padding: 1rem 1.5rem;

  p {
    font-size: var(--ms-2);
    font-family: var(--font-family-serif);
    font-style: italic;
  }

  /*
  p::before {
    content: open-quote;
    display: inline;
  }

  p::after {
    content: close-quote;
  }
  */
}

.block--quote blockquote footer {
  font-size: var(--ms-neg-1);
  font-style: italic;
}

/* BLOCK Text */

.block--text .text-has-columns {
  @media (--viewport-medium) {
    --flow-space: 1.5rem;
    columns: 2;

    & p {
      padding-right: 1rem;
    }
  }
  @media (--viewport-large) {
    & p {
      padding-right: 1.5rem;
    }
  }
}

/* Media (src: Starterkit) */

.video,
.img {
  position: relative;
  display: block;
  --w: 1;
  --h: 1;
  padding-bottom: calc(100% / var(--w) * var(--h));
  background: var(--color-black);
}

.img img,
.video iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 0;
}

.img[data-contain] img {
  object-fit: contain;
}

.img-caption,
.video-caption {
  padding-top: 0.75rem;
  line-height: 1.5em;
  color: var(--color-grey-client);
  font-size: 13px;
}

/* Related Pages */

.related-pages .section-title {
  color: var(--color-secondary);
}

/*
  ------------------------
  Subpages Layout
  ------------------------
*/

.article-main > .wrap {
  position: relative;
}

@media (--viewport-large) {
  .article-subpages {
    height: 0;
    position: absolute;
    top: 0;
    left: var(--outer-gutters);
    right: var(--outer-gutters);
  }

  .subpages-navigation {
    @mixin grid;
  }

  .subpages-menu {
    grid-column: 19 / -1;
  }
}

/* Skip to Subpages */

html {
  scroll-behavior: smooth;
}

.skip-to-subpages-menu {
  display: none;
  @mixin main-navigation-link;
  font-size: var(--ms-1) !important;
  background: var(--color-primary);
  color: var(--color-white);
  padding: 0.5rem 1rem;
  margin-bottom: var(--flow-space);
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  & svg {
    height: 0.75rem;
    width: auto;
    margin-right: 0.5rem;
  }

  & path {
    fill: var(--color-white);
  }
}

@media (--viewport-smallest-to-large) {
  .skip-to-subpages-menu {
    display: inline-block;
  }
}
