.breadcrumb-menu {
  @mixin uiList;
  /* display: flex; */
  display: none;
  margin-left: -1rem;
  
  & a {
    color: var(--color-grey)
  }
}

.breadcrumb-menu > li {
  padding-left: 1rem;
  font-size: var(--ms-neg-1);
}
