@charset "utf-8";

@import "../../node_modules/modern-css-reset/dist/reset.css";
@import "../../node_modules/tiny-slider/dist/tiny-slider.css";
@import "../../node_modules/basiclightbox/dist/basicLightbox.min.css";

@import "global/fonts.css";
@import "global/base.css";

@import "global/vars.css";
@import "global/typography.css";
@import "global/layout.css";
@import "global/colors.css";

@import "components/header.css";
@import "components/footer.css";
@import "components/page.css";
@import "components/archive.css";
@import "components/article-header.css";
@import "components/hero.css";
@import "components/breadcrumb-menu.css";
@import "components/subpages-menu.css";
@import "components/cards.css";
@import "components/related-pages.css";
@import "components/a11y-menu.css";

/* @import "components/van11y-accessible-modal-window-aria.css"; */

pre {
  overflow-x: hidden;  
}