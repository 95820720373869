html {
  font-size: 100%;

  @media (--viewport-medium) {
    font-size: 112.5%;
  }
}

body {
  font-family: var(--font-family-sans);
  font-weight: 400;
  line-height: 1.5;
}

/* Headings */

:--heading {
  font-weight: 600;
  font-size: 1rem;
}

/* Misc */

ul {
  margin: 0;
  padding-left: 2rem;
}

@define-mixin mainTitle {
  font-size: var(--ms-4);
  line-height: 1.25;
  letter-spacing: 0.05em;
  font-weight: 300;
  max-width: 18em;

  @media (--viewport-medium) {
    font-size: var(--ms-3);
  }
  @media (--viewport-large) {
    font-size: var(--ms-4);
    line-height: 1.1;
  }
  @media (--viewport-extra-large) {
    font-size: var(--ms-5);
    line-height: 1.1;
  }
}

.page-title {
  @mixin mainTitle;
}

/* builder h1, h2 */
@define-mixin sectionTitle {
  font-size: var(--ms-1);
  line-height: 1.25;
  letter-spacing: 0.02em;
  font-weight: 400;
}

.section-title {
  @mixin sectionTitle;
}

@define-mixin main-navigation-link {
  text-transform: uppercase;
  letter-spacing: 0.075em;

  @media (--viewport-smallest-to-large) {
    font-size: var(--ms-neg-1);
  }
}

/* builder h3 */
@define-mixin smallTitle {
  font-size: var(--ms-0);
  line-height: 1.25;
  letter-spacing: 0.02em;
  font-weight: 600;
}

@define-mixin taxonomyLabel {
  text-transform: uppercase;
  font-size: var(--ms-neg-1);
  font-weight: 400;
  letter-spacing: 0.02em;
}

@define-mixin big-paragraph {
  font-size: var(--ms-1);
  font-family: var(--font-family-serif);
  line-height: 1.5;

  @media (--viewport-medium) {
    font-size: var(--ms-1);
  }
}

/* Flow */

.flow > * + * {
  margin-top: var(--flow-space, 1rem);
}
