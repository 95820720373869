.subpages-menu {
  @mixin uiList;

  border-top: 1px solid var(--color-primary);
}

.subpages-menu > li {
  border-bottom: 1px solid var(--color-primary);
}

.subpages-menu > li > a {
  color: var(--color-primary);
  display: block;
  padding: 0.75rem 1rem;
}

.subpages-menu > li.active a {
  background: var(--color-primary-lighter);
}

.subpages-menu > li:hover a {
  background: var(--color-primary-lighter);
}

.subpages-menu a:hover {
  text-decoration: none;
}

@media (--viewport-large) {
  .subpages-navigation {
    pointer-events: none;
  }

  .subpages-menu > li > a {
    pointer-events: auto;
  }
}
