/* Layout */

.article-header .wrap {
  @mixin grid;
}

@media (--viewport-large) {

  .article-header .wrap > * {
    grid-column: 1 / 18;
  }
}

/* Styles */

.article-header * {
  color: currentColor;
}

.article-header {
  @mixin headerGradient;
  color: var(--color-white);
  overflow: hidden;

  & :--heading {
    margin-bottom: 2rem;
  }
}

.article-header-meta {
  padding-top: calc(var(--header-height) );

  @media (--viewport-medium) {
    padding-top: calc(var(--header-height) + var(--flow-space-8));
  }
}

/* Bleed */

.article {
  --bleed-distance: 0rem;
}

.article--has-cover {
  --bleed-distance: var(--flow-space-2);

  @media (--viewport-medium) {
    --bleed-distance: var(--flow-space-4);
  }
}

.article--has-cover .article-header-cover {
  margin-bottom: calc(-2 * var(--bleed-distance));
}

.article--has-cover .article-header {
  overflow: visible;
  margin-bottom: var(--bleed-distance);
}

.article-main {
  padding-top: calc(var(--flow-space-2) + var(--bleed-distance));
  background: #fff;

  @media (--viewport-medium) {
    padding-top: calc(var(--flow-space-4) + var(--bleed-distance));
  }
}

.article:not(.article--has-cover) {
  /* padding-top: 800px; */
}
