/* Layout */

.cards:not(.tns-slider) {
  --gap: calc(2 * var(--flow-space));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: var(--gap);
}

.card a {
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-content {
  flex: 1;
}

/* Card */

.post-type {
  @mixin taxonomyLabel;
  display: inline-block;

  background: var(--color-primary);
  color: var(--color-white);
  padding: 0.25rem 2rem;
}

.card {
  --flow-space: 1rem;

  /* max-width: 20rem; */
}

.card-title {
  @mixin sectionTitle;
}

@media (hover: hover) and (pointer: fine) {

  .card a:hover {
    text-decoration: none;

    & .card-content {
      background: var(--color-primary-lighter);
    }
  }
}

.card-content {
  padding-top: calc(var(--flow-space) / 1.5);
  padding-bottom: calc(var(--flow-space) / 1);
  

  box-shadow: inset 0 1px 0 0 var(--color-primary),
    inset 0 -1px 0 0 var(--color-primary);
}

.card-cover {
  width: 100%;
  aspect-ratio: 270 / 210;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-primary-ultralight);

  position: relative;
  max-width: 500px;
  aspect-ratio: 16 / 9;
}

.card-cover img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.card-excerpt {
  font-size: var(--ms-neg-1);


  & p {
    font-size: inherit;
  }
}

.card-excerpt, .card :--heading {
  padding-left: var(--flow-space);
  padding-right: var(--flow-space);
}


.spawnpoint-pin {
  width: 4rem;
  height: 4rem;
  text-align: center;

  & svg {
    height: 100%;
    width: auto;
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.3));
  }

  & path {
    fill: var(--color-white);
  }
}
