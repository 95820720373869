.filter {
  /* margin-top: -1rem; */
  margin-bottom: 2rem;

  @media (--viewport-medium) {
    /* margin-top: -2rem; */
    margin-bottom: 4rem;
  }
}

.filter h2 {
  font-family: var(--font-family-serif);
  font-weight: normal;
  font-size: var(--ms-1);
  margin-bottom: 1rem;
}

.filter > * + h2 {
  margin-top: 1rem;
}

.filter .tags {
  @mixin uiList;
  display: flex;
  flex-wrap: wrap;
}

.filter .tags a {
  padding: 0.25rem 1rem;
  font-size: var(--ms-neg-1);
  color: var(--color-primary);
  box-shadow: inset 0 0 0 1px var(--color-primary);
  text-transform: uppercase;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.filter .tags [aria-current] {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.pagination ul {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @mixin uiList;
  display: flex;
  justify-content: center;
}

.pagination a {
  @mixin paginationEl;
}

.pagination [aria-current] {
  @mixin paginationEl:active;
}
