:root {
  --outer-gutters: 2rem;

  @media (--viewport-medium) {
    --outer-gutters: 2rem;
  }
}

:root {
  --flow-space: 0.75rem;
  --flow-space-2: calc(2 * var(--flow-space));
  --flow-space-3: calc(3 * var(--flow-space));
  --flow-space-4: calc(4 * var(--flow-space));
  --flow-space-6: calc(6 * var(--flow-space));
  --flow-space-8: calc(8 * var(--flow-space));
  --flow-space-10: calc(10 * var(--flow-space));
  --flow-space-12: calc(12 * var(--flow-space));
  --flow-space-14: calc(14 * var(--flow-space));
}

:root {
  --scale: 1.125;

  /* scale derived */
  --ms-5: calc(
    1rem * var(--scale) * var(--scale) * var(--scale) * var(--scale) *
      var(--scale)
  );
  --ms-4: calc(
    1rem * var(--scale) * var(--scale) * var(--scale) * var(--scale)
  );
  --ms-3: calc(1rem * var(--scale) * var(--scale) * var(--scale));
  --ms-2: calc(1rem * var(--scale) * var(--scale));
  --ms-1: calc(1rem * var(--scale));
  --ms-0: 1rem;
  --ms-neg-1: calc(1rem / var(--scale));
  --ms-neg-2: calc(1rem / var(--scale) / var(--scale));

  /* font families */
  --font-family-sans: "Source Sans Pro", system-ui, sans-serif;
  --font-family-serif: "Droid Serif", serif;

  @media (--viewport-medium) {
    --scale: 1.25;
  }
}

/* @media --scale: 1.X  */

@custom-media --viewport-smallest (width <= 24rem);
@custom-media --viewport-small (width > 24rem);
@custom-media --viewport-medium (width > 40rem);
@custom-media --viewport-large (width > 62rem);
@custom-media --viewport-extra-large (width > 70rem);

@custom-media --viewport-smallest-to-medium (width <= 40rem);
@custom-media --viewport-smallest-to-large (width <= 62rem);

@custom-selector :--heading h1, h2, h3, h4, h5, h6;

body::after {
  content: "";

  display: none;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  background: whitesmoke;
  padding: 0.25rem;
  opacity: 0.5;

  @media (--viewport-smallest) {
    content: "--viewport-smallest";
  }

  @media (--viewport-small) {
    content: "--viewport-small";
  }

  @media (--viewport-medium) {
    content: "--viewport-medium";
  }

  @media (--viewport-large) {
    content: "--viewport-large";
  }

  @media (--viewport-extra-large) {
    content: "--viewport-extra-large";
  }
}
